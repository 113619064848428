import React  from 'react';
import "../css/Footer.css"
const Footers = () => {


  return (
    <>
      <span className='footers'>
          <p>ⓒ 2024 by koj</p>
      </span>
    </>
  )
}



export default Footers;