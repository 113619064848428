import "../../../components/css/Mypage.css";
const Conditions = () => {

    return (
        <>
            <div className="conditionsPage">
                <h1>이용 약관</h1>
                제 1 장 총칙 <br/>

                제 1 조 (목적)<br/>
                본 약관은 팀 코쟁이가 운영하는 웹 사이트의 제반 서비스의 이용조건 및 절차에 관한 사항 및 기타 필요한 사항을 규정함을 목적으로 한다.

                <br/> 제 2 조 (용어의 정의)
                <br/> 본 약관에서 사용하는 용어는 다음과 같이 정의한다.
                <br/> ①회원 : 기본 회원 정보를 입력하였고, 회사와 서비스 이용계약을 체결하여 아이디를 부여받은 개인
                <br/> ②아이디(ID) : 회원식별과 회원의 서비스 이용을 위해 회원이 선정하고 회사가 승인하는 문자와 숫자의 조합
                <br/>③비밀번호(Password) : 회원이 통신상의 자신의 비밀을 보호하기 위해 선정한 문자와 숫자의 조합
                <br/>④해지 : 회사 또는 회원에 의한 이용계약의 종료
                <br/>
                <br/>제 3 조 (약관의 공시 및 효력과 변경)
                <br/>①본 약관은 회원가입 화면에 게시하여 공시하며 회사는 사정변경 및 영업상 중요한 사유가 있을 경우 약관을 변경할 수 있으며 변경된 약관은 공지사항을 통해 공시한다
                <br/> ②본 약관 및 차후 회사사정에 따라 변경된 약관은 이용자에게 공시함으로써 효력을 발생한다.
                <br/>
                <br/> 제 4 조 (약관 외 준칙)
                <br/> 본 약관에 명시되지 않은 사항이 전기통신기본법, 전기통신사업법, 정보통신촉진법, ‘전자상거래등에서의 소비자 보호에 관한 법률’, ‘약관의 규제에관한법률’, ‘전자거래기본법’, ‘전자서명법’, ‘정보통신망 이용촉진등에 관한 법률’, ‘소비자보호법’ 등 기타 관계 법령에 규정되어 있을 경우에는 그 규정을 따르도록 한다.
                <br/>
                <br/>제 2 장 이용계약
                <br/>
                <br/>제 5 조 (이용신청)
                <br/>①이용신청자가 회원가입 안내에서 본 약관과 개인정보보호정책에 동의하고 등록절차(회사의 소정 양식의 가입 신청서 작성)를 거쳐 '확인' 버튼을 누르면 이용신청을 할 수 있다.
                <br/>②이용신청자는 반드시 실명과 실제 정보를 사용해야 하며 1개의 생년월일에 대하여 1건의 이용신청을 할 수 있다.
                <br/>③실명이나 실제 정보를 입력하지 않은 이용자는 법적인 보호를 받을 수 없으며, 서비스 이용에 제한을 받을 수 있다.
                <br/>
                <br/>제 6 조 (이용신청의 승낙)
                <br/>①회사는 제5조에 따른 이용신청자에 대하여 제2항 및 제3항의 경우를 예외로 하여 서비스 이용을 승낙한다.
                <br/> ②회사는 아래 사항에 해당하는 경우에 그 제한사유가 해소될 때까지 승낙을 유보할 수 있다.
                <br/>가. 서비스 관련 설비에 여유가 없는 경우
                <br/>나. 기술상 지장이 있는 경우
                <br/>다. 기타 회사 사정상 필요하다고 인정되는 경우
                <br/>③회사는 아래 사항에 해당하는 경우에 승낙을 하지 않을 수 있다.
                <br/>가. 다른 사람의 명의를 사용하여 신청한 경우
                <br/>나. 이용자 정보를 허위로 기재하여 신청한 경우
                <br/>다. 사회의 안녕질서 또는 미풍양속을 저해할 목적으로 신청한 경우
                <br/>라. 기타 회사가 정한 이용신청 요건이 미비한 경우
                <br/>
            </div>
        </>
    )

}
export default Conditions;